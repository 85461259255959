"use client";
import { signIn } from "next-auth/react";
import { Button } from "@nextui-org/react";
import { TbBrandGoogle } from "react-icons/tb";

const GoogleLogin: React.FC = () => {
  return (
    <div className="w-full absolute bottom-0 p-5 left-0">
      <Button
        isIconOnly
        color="primary"
        radius="sm"
        className="p-3 h-auto w-full flex items-center justify-center text-white"
        onClick={async () => {
          await signIn("google", undefined, {
            redirect_uri: `${window.location.origin}/api/auth/callback/google`,
          });
        }}
      >
        <div className="flex items-center gap-2">
          <TbBrandGoogle size={20} />
          Ingresar con Google
        </div>
      </Button>
    </div>
  );
};

export default GoogleLogin;
